<template>
  <router-view />
</template>

<script>
/* eslint-disable */
import trans from "@/resources/trans.json";

export default {
  name: "Verbis",
  data() {
    return {
      trans
    };
  },
  computed: {
    language: {
      get() {
        return this.$store.getters.language;
      },
      set(value) {
        this.$store.commit("updateLanguage", value);
      }
    },
    loading() {
      return this.$store.getters.loading;
    }
  },
  mounted() {
    this.$store.dispatch("getData");
  }
};
</script>
