<template>
  <!-- Page header -->
  <div style="background-color: #ab1a2d">
    <img
      src="@/assets/logo.png"
      class="mx-auto"
      alt="АГЕНЦИЈА ЗА ПРИМЕНА НА ЈАЗИКОТ ШТО ГО ЗБОРУВААТ НАЈМАЛКУ 20% ОД ГРАГАНИТЕ НА РЕПУБЛИКА СЕВЕРНА
      МАКЕДОНИЈА"
    />
  </div>

  <!-- Network indicator -->
  <div v-show="!online" class="bg-red-500 text-white text-center py-2 animate-pulse">
    <h1 class="text-xs font-medium"><i class="fal fa-wifi-slash fa-lg mr-1"></i> No internet connection</h1>
  </div>

  <!-- Container -->
  <div class="container my-4 md:my-8">
    <div class="max-w-screen-md mx-auto text-center space-y-2 mb-8">
      <h1 class="text-xl font-medium">{{ trans[this.language].welcome }}</h1>
      <h3 class="text-lg">{{ trans[this.language].heading }}</h3>
      <p class="text-xs">{{ trans[this.language].p1 }}</p>
      <p class="text-xs">{{ trans[this.language].p2 }}</p>
    </div>

    <div class="grid grid-cols-1 gap-y-4 lg:grid-cols-12 lg:gap-4">
      <!-- Category radios -->
      <div class="col-span-2">
        <div class="mt-1 space-y-2">
          <div class="flex items-center" v-for="cat in categories" :key="cat.id">
            <input
              :id="cat.id"
              name="category"
              type="radio"
              class="h-4 w-4 text-gray-600 border-gray-300 focus:ring-gray-500"
              :value="cat.id"
              :disabled="!cat.status"
              v-model="category"
            />
            <label
              :for="cat.id"
              class="ml-3 block text-sm font-medium text-gray-400"
              :class="{ 'text-gray-700': cat.status }"
            >
              {{ cat[this.language] }}
            </label>
          </div>
        </div>
        <div class="mt-8 text-center lg:text-left space-y-2">
          <router-link to="/korpus" class="block font-medium tracking-wide text-primary">{{
            trans[this.language].korpus
          }}</router-link>
          <a
            href="https://app.box.com/s/doxl73v702q6d57zyqtiwqet2ku6dkut"
            class="block font-medium tracking-wide text-primary"
            >Download the Android App</a
          >
          <img src="@/assets/qr.png" alt="Android app qr code" class="hidden lg:block h-48 mx-auto lg:mx-0" />
        </div>
      </div>

      <!-- Search input -->
      <div class="col-span-7 space-y-8">
        <form class="mt-1 flex flex-wrap gap-y-2 rounded-md" v-on:submit.prevent="submitSearch" autocomplete="off">
          <select
            v-model="language"
            id="language"
            name="language"
            class="
              block
              w-min
              py-6
              pr-6
              text-xs
              sm:text-sm
              rounded-l-md
              shadow-sm
              border border-r-0 border-gray-300
              focus:ring-gray-500 focus:border-gray-500
            "
          >
            <option value="mkd">Македонски</option>
            <option value="alb">Shqip</option>
          </select>
          <input
            v-model="search"
            type="text"
            name="search"
            id="search"
            class="
              py-6
              px-2
              block
              flex-grow
              text-base
              sm:text-sm
              border-gray-300
              shadow-sm
              md:rounded-r-md
              focus:ring-gray-500 focus:border-gray-500
            "
            :placeholder="trans[this.language].search"
          />
          <button
            type="submit"
            class="
              md:ml-2
              p-2
              px-4
              lg:px-6
              border border-transparent
              shadow-sm
              rounded-r-md
              md:rounded-md
              text-sm
              font-medium
              text-white
              bg-gray-800
              hover:bg-gray-600
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800
            "
            aria-label="Search"
          >
            <i class="fal fa-search fa-lg"></i>
          </button>
        </form>

        <!-- API loader -->
        <div v-if="loading && search" class="flex items-center justify-center space-x-2">
          <i class="fal fa-sync fa-2x fa-spin"></i>
        </div>

        <!-- Error message -->
        <div v-if="error" class="p-4 rounded-lg bg-primary shadow text-gray-50 space-y-2">
          <p class="font-medium"><i class="fal fa-exclamation-circle fa-lg mr-1"></i> Something went wrong</p>
          <p class="text-sm">Try refreshing the page or come back later</p>
          <p class="text-xs">{{ errorMessage }}</p>
        </div>

        <!-- API results -->
        <div v-if="results.data?.length && !loading && !error" class="mx-auto px-4 overflow-hidden" ref="list">
          <div class="flex flex-wrap items-center justify-between gap-4 mb-4">
            <h1 class="text-3xl font-bold">{{ search }}</h1>
            <p class="text-sm">Showing {{ results.data.length }} of {{ results.meta.total }} results</p>
          </div>

          <template v-for="(result, index) in results.data" :key="result.id">
            <div
              class="space-y-4 py-8"
              @mouseover="
                hover = true;
                hoverId = result.id;
              "
              @mouseleave="hover = false"
            >
              <div class="flex items-center">
                <img
                  src="@/assets/alb.svg"
                  alt="Albanian flag"
                  v-show="language == 'alb' && selected.id == result.id"
                  class="w-8 h-8"
                />
                <img
                  src="@/assets/mkd.svg"
                  alt="Macedonian flag"
                  v-show="language == 'mkd' && selected.id == result.id"
                  class="w-8 h-8"
                />
                <h3 class="text-xl font-semibold ml-2">
                  {{ selected.id == result.id ? "" : index + 1 + ". " }} {{ result[this.language] }}
                </h3>
              </div>
              <transition
                enter-active-class="transition duration-300 ease-in-out"
                enter-from-class="transform scale-100 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-300 ease-in-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-100 opacity-0"
              >
                <p v-show="(hover && hoverId == result.id) || selected.id == result.id" class="text-gray-900">
                  {{ result["description_" + this.language] }}
                </p>
              </transition>

              <!-- Selected -->
              <template v-if="selected.id == result.id">
                <div class="py-2 flex items-center text-xl font-semibold">
                  <img v-show="language == 'mkd'" src="@/assets/alb.svg" alt="Albanian flag" class="w-8 h-8" />
                  <img v-show="language == 'alb'" src="@/assets/mkd.svg" alt="Macedonian flag" class="w-8 h-8" />
                  <h3 class="text-xl font-semibold ml-2">
                    {{ this.language == "mkd" ? this.selected.alb : this.selected.mkd }}
                  </h3>
                </div>
                <p class="text-gray-900">
                  {{ this.language == "mkd" ? this.selected.description_alb : this.selected.description_mkd }}
                </p>
              </template>

              <button class="text-sm underline" @click="select(result.id)">
                {{ selected.id == result.id ? trans[this.language].hide : trans[this.language].show }}
              </button>
            </div>
          </template>

          <div v-show="pageLoading" class="flex items-center justify-center space-x-2">
            <i class="fal fa-sync fa-2x fa-spin"></i>
          </div>
        </div>
        <div v-if="search.length > 2 && !results.data?.length && !loading && !error">
          {{ trans[this.language].noresults }} <em>{{ search }}</em>
        </div>
      </div>

      <!-- New definition form -->
      <div class="col-span-3">
        <form class="sticky top-4" v-on:submit.prevent="submitNew" autocomplete="off">
          <transition
            enter-active-class="transition duration-300 ease-in-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-300 ease-in-out"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <div v-show="formStatus" class="mb-2">
              <p class="text-green-600">
                <i class="fal fa-check-circle"></i>
                {{ trans[this.language].success }}
              </p>
            </div>
          </transition>
          <transition
            enter-active-class="transition duration-300 ease-in-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-300 ease-in-out"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <div v-show="formStatus == false" class="mb-2">
              <p class="text-red-500">
                <i class="fal fa-exclamation-triangle"></i>
                {{ trans[this.language].error }}
              </p>
            </div>
          </transition>
          <div class="space-y-4">
            <h2 class="text-2xl">{{ trans[this.language].form }}</h2>
            <div class="grid grid-cols-1 gap-4">
              <div>
                <label for="mkd" class="block text-sm font-medium text-gray-700">
                  {{ this.language == "mkd" ? trans.mkd.title : trans.alb.title_mkd }}
                </label>
                <input
                  type="text"
                  name="mkd"
                  id="mkd"
                  v-model="form.mkd"
                  class="
                    mt-1
                    focus:ring-gray-500 focus:border-gray-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  required
                />
              </div>

              <div>
                <label for="mkd_exp" class="block text-sm font-medium text-gray-700">
                  {{ this.language == "mkd" ? trans.mkd.desc : trans.alb.desc_mkd }}
                </label>
                <textarea
                  name="mkd_exp"
                  id="mkd_exp"
                  v-model="form.description_mkd"
                  cols="30"
                  rows="3"
                  class="
                    mt-1
                    focus:ring-gray-500 focus:border-gray-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  required
                ></textarea>
              </div>

              <div>
                <label for="alb" class="block text-sm font-medium text-gray-700">
                  {{ this.language == "mkd" ? trans.mkd.title_alb : trans.alb.title }}
                </label>
                <input
                  type="text"
                  name="alb"
                  id="alb"
                  v-model="form.alb"
                  class="
                    mt-1
                    focus:ring-gray-500 focus:border-gray-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  required
                />
              </div>

              <div>
                <label for="alb_exp" class="block text-sm font-medium text-gray-700">
                  {{ this.language == "mkd" ? trans.mkd.desc_alb : trans.alb.desc }}
                </label>
                <textarea
                  name="alb_exp"
                  id="alb_exp"
                  v-model="form.description_alb"
                  cols="30"
                  rows="3"
                  class="
                    mt-1
                    focus:ring-gray-500 focus:border-gray-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  required
                ></textarea>
              </div>

              <div>
                <label for="category" class="block text-sm font-medium text-gray-700">
                  {{ trans[this.language].cat }}
                </label>
                <select
                  id="category"
                  name="category"
                  v-model="form.category_id"
                  class="
                    mt-1
                    block
                    w-full
                    py-2
                    px-3
                    border border-gray-300
                    bg-white
                    rounded-md
                    shadow-sm
                    focus:outline-none focus:ring-gray-500 focus:border-gray-500
                    sm:text-sm
                  "
                  required
                >
                  <option v-for="cat in categories" :key="cat.id" :value="cat.id">{{ cat[this.language] }}</option>
                </select>
              </div>
            </div>
            <div class="text-right">
              <button
                type="submit"
                class="
                  py-2
                  px-4
                  border border-transparent
                  shadow-sm
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-gray-800
                  hover:bg-gray-600
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800
                "
                :disabled="formLoading"
              >
                {{ formLoading ? "" : trans[this.language].submit }}
                <div v-show="formLoading"><i class="fal fa-spinner-third fa-lg fa-spin"></i></div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <footer class="p-8 text-sm text-white" style="background-color: #ab1a2d">
    <div class="flex flex-wrap justify-center space-x-8">
      <p><span class="italic">&copy;</span> АПЈ / AZGJ</p>
      <p>{{ trans[this.language].address }}</p>
      <a href="mailto:info@apj.gov.mk" class="underline">info@apj.gov.mk</a>
      <p>+389 2 312 1731</p>
      <p>+389 72 916 655</p>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from "vue";
import debounce from "lodash.debounce";
import api from "@/lib/api";
import trans from "@/resources/trans.json";

export default defineComponent({
  name: "Home",
  data() {
    return {
      category: 1,
      trans,
      search: "",
      results: [],
      nextPage: null,
      selected: false,
      hover: false,
      hoverId: -1,
      online: navigator.onLine,
      form: {
        mkd: "",
        description_mkd: "",
        alb: "",
        description_alb: "",
        category_id: 1
      },
      formStatus: null,
      formLoading: false,
      pageLoading: false
    };
  },
  computed: {
    language: {
      get() {
        return this.$store.getters.language;
      },
      set(value) {
        this.$store.commit("updateLanguage", value);
      }
    },
    loading() {
      return this.$store.getters.loading;
    },
    error() {
      return this.$store.getters.error;
    },
    errorMessage() {
      return this.$store.getters.errorMessage;
    },
    words() {
      return this.$store.getters.wordsData;
    },
    categories() {
      return this.$store.getters.categories;
    }
  },
  watch: {
    words: function(newValue, oldValue) {
      if (!oldValue.length && newValue.length) this.submitSearch();
    },
    language: function(value) {
      localStorage.setItem("language", value);
    },
    search: function() {
      this.submitSearch();
    }
  },
  mounted() {
    window.addEventListener("online", this.networkChanged);
    window.addEventListener("offline", this.networkChanged);
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: debounce(function() {
      if (!this.nextPage) return;

      const element = this.$refs.list;
      if (element && element.getBoundingClientRect().bottom < window.innerHeight) {
        this.pageLoading = true;

        api
          .get(this.nextPage)
          .then(res => {
            this.nextPage = res.data.links.next;
            this.results.data.push(...res.data.data);
          })
          .catch(console.error)
          .finally(() => (this.pageLoading = false));
      }
    }, 500),
    submitSearch: debounce(function() {
      if (this.search.length < 2) {
        this.results = [];
      } else {
        this.selected = false;

        api
          .get(`/api/words?category=${this.category}&search=${this.search}&language=${this.language}&per_page=15`)
          .then(res => {
            this.results = res.data;
            this.nextPage = res.data.links.next;
          })
          .catch(console.error);
      }
    }, 350),

    submitNew() {
      this.formLoading = true;

      api
        .post("/api/words", this.form)
        .then(() => {
          this.form.mkd = "";
          this.form.description_mkd = "";
          this.form.alb = "";
          this.form.description_alb = "";
          this.formStatus = true;
        })
        .catch(err => {
          console.error(err);
          this.formStatus = false;
        })
        .finally(() => (this.formLoading = false));
    },

    select(id) {
      this.selected.id == id
        ? (this.selected = false)
        : (this.selected = this.words.find(word => word.id == id) ?? this.results.data.find(word => word.id == id));
    },

    networkChanged() {
      this.online = navigator.onLine;
      this.$emit(this.online ? "online" : "offline");
    }
  },
  beforeUnMount() {
    /* Clear event listeners */
    window.removeEventListener("online", this.networkChanged);
    window.removeEventListener("offline", this.networkChanged);
    window.removeEventListener("scroll", this.handleScroll);
  }
});
</script>
