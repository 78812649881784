import environment from "@/environment";
import axios from "axios";

const api = axios.create({
  baseURL: environment.baseUrl,
  headers: {
    Accept: "application/json"
  }
});

export default api;
