/* eslint-disable */
import { createStore } from "vuex";
import api from "./lib/api";

export const store = createStore({
  state() {
    return {
      loading: true,
      error: false,
      errorMessage: "",
      wordsData: [],
      categories: [],
      language: localStorage.getItem("language") ?? "mkd"
    };
  },
  actions: {
    getData({ commit }) {
      // Get all categories
      api
        .get("/api/categories")
        .then(response => {
          commit("updateCategories", response.data.data);
        })
        .catch(error => {
          commit("error", true);

          if (error.response) {
            console.error(error.response.data);
            commit("errorMessage", error.response.data);
          } else {
            console.error(error.message);
            commit("errorMessage", error.message);
          }
        });

      // Get starting words, paginated
      api
        .get("/api/words?per_page=25")
        .then(response => {
          commit("updateWords", Object.freeze(response.data.data));
          commit("error", false);
          commit("errorMessage", "");
        })
        .catch(error => {
          commit("error", true);

          if (error.response) {
            console.error(error.response.data);
            commit("errorMessage", error.response.data);
          } else {
            console.error(error.message);
            commit("errorMessage", error.message);
          }
        })
        .finally(() => commit("loading", false));
    }
  },
  mutations: {
    updateLanguage(state: any, newValue) {
      state.language = newValue;
    },
    loading(state: any, newValue) {
      state.loading = newValue;
    },
    error(state: any, newValue) {
      state.error = newValue;
    },
    errorMessage(state: any, newValue) {
      state.errorMessage = newValue;
    },
    updateWords(state: any, newValue) {
      state.wordsData = newValue;
    },
    updateCategories(state: any, newValue) {
      state.categories = newValue;
    }
  },
  getters: {
    language(state: any) {
      return state.language;
    },
    loading(state: any) {
      return state.loading;
    },
    error(state: any) {
      return state.error;
    },
    errorMessage(state: any) {
      return state.errorMessage;
    },
    wordsData(state: any) {
      return state.wordsData;
    },
    categories(state: any) {
      return state.categories;
    }
  }
});
