<template>
  <!-- Page header -->
  <div style="background-color: #ab1a2d">
    <img
      src="@/assets/logo.png"
      class="mx-auto"
      alt="АГЕНЦИЈА ЗА ПРИМЕНА НА ЈАЗИКОТ ШТО ГО ЗБОРУВААТ НАЈМАЛКУ 20% ОД ГРАГАНИТЕ НА РЕПУБЛИКА СЕВЕРНА
      МАКЕДОНИЈА"
    />
  </div>

  <div v-if="loading || error" class="container max-w-screen-md mt-8">
    <!-- Loading spinner -->
    <div v-if="loading" class="flex items-center justify-center mt-8">
      <i class="fal fa-sync fa-2x fa-spin"></i>
    </div>

    <!-- Error message -->
    <div v-if="error" class="p-4 rounded-lg bg-primary shadow text-gray-50 space-y-2">
      <p class="font-medium"><i class="fal fa-exclamation-circle fa-lg mr-1"></i> Something went wrong</p>
      <p class="text-sm">Try refreshing the page or come back later</p>
      <p class="text-xs">{{ errorMessage }}</p>
    </div>
  </div>

  <div class="container max-w-screen-md my-8 sticky top-0 z-50 bg-white">
    <div class="flex items-center space-x-4">
      <router-link to="/" class="px-6 py-3 rounded-lg shadow bg-primary text-gray-50"
        ><i class="fal fa-arrow-left fa-lg"></i
      ></router-link>
      <select
        v-model="language"
        id="language"
        name="language"
        class="
          block
          py-6
          pr-8
          text-base
          lg:text-xs
          sm:text-sm
          rounded-md
          shadow-sm
          border border-gray-300
          focus:ring-gray-500 focus:border-gray-500
        "
      >
        <option value="mkd">Македонски</option>
        <option value="alb">Shqip</option>
      </select>
    </div>
  </div>

  <div ref="list">
    <DynamicScroller :items="items" :min-item-size="200">
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :data-index="index"
          class="max-w-screen-md mx-auto px-4 space-y-2 my-8"
        >
          <h3 class="text-xl font-semibold">{{ item.mkd }}</h3>
          <p class="text-gray-900">{{ item.description_mkd }}</p>
          <h3 class="text-xl font-semibold">{{ item.alb }}</h3>
          <p class="text-gray-900">{{ item.description_alb }}</p>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </div>
</template>

<script>
import api from "@/lib/api";
import { defineComponent } from "vue";
import debounce from "lodash.debounce";

/* Virtual scroller */
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default defineComponent({
  name: "Korpus",
  components: { DynamicScroller, DynamicScrollerItem },
  data() {
    return {
      loading: false,
      items: [],
      next: null
    };
  },
  computed: {
    language: {
      get() {
        return this.$store.getters.language;
      },
      set(value) {
        this.$store.commit("updateLanguage", value);
      }
    },
    error() {
      return this.$store.getters.error;
    },
    errorMessage() {
      return this.$store.getters.errorMessage;
    }
  },
  watch: {
    language: function(newValue, oldValue) {
      if (newValue === oldValue) return;

      localStorage.setItem("language", newValue);
      this.getData();
    }
  },
  methods: {
    getData() {
      this.loading = true;

      api
        .get(`/api/words?language=${this.language}&per_page=50`)
        .then(res => {
          this.items = res.data.data;
          this.next = res.data.links.next;
        })
        .catch(console.error)
        .finally(() => (this.loading = false));
    },
    handleScroll: debounce(function() {
      if (!this.next) return;

      const element = this.$refs.list;
      if (element && element.getBoundingClientRect().bottom <= window.innerHeight * 2) {
        console.log("Passed");
        this.pageLoading = true;

        api
          .get(this.next)
          .then(res => {
            this.next = res.data.links.next;
            this.items.push(...res.data.data);
          })
          .catch(console.error);
      }
    }, 300)
  },
  mounted() {
    this.getData();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnMount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
});
</script>
